@charset "UTF-8";

$break-points: (
	sm: 699,
	md: 999,
);


@function mq($point:"sm"){
	@return map-get($break-points,$point);
}

@mixin sm {
	@media screen and (max-width: #{mq("sm")}px) {
		@content;
	}
}

@mixin md {
	@media screen and (min-width: #{mq("sm")+1}px) and (max-width: #{mq("md")}px) {
		@content;
	}
}

@mixin lg {
	@media screen and (min-width: #{mq("md")}px) {
		@content;
	}
}


@mixin sp{
	@include sm(){
		@content;
	}
}

@mixin tb{
	@include md(){
		@content;
	}
	@include sm(){
		@content;
	}
}

@mixin pc{
	@include md(){
		@content;
	}
	@include lg(){
		@content;
	}
}


@mixin under($px) {
	@media screen and (max-width: #{$px}px) {
		@content;
	}
}

@mixin over($px) {
	@media screen and (min-width: #{$px}px) {
		@content;
	}
}

