@charset "UTF-8";

@font-face {
	font-family: "DIN Condenced";
	font-style: normal;
	font-weight: 600;
	src: url("/assets/font/DIN-Condensed-Bold.woff2") format("woff2"),
		url("/assets/font/DIN-Condensed-Bold.woff") format("woff"),
		url("/assets/font/DIN-Condensed-Bold.otf") format("opentype");
}
@font-face {
	font-family: "Helvetica";
	font-style: bold;
	font-weight: 300;
	src: url("/assets/font/HelveticaNeueLTStd-Lt.woff2") format("woff2"),
		url("/assets/font/HelveticaNeueLTStd-Lt.woff") format("woff"),
		url("/assets/font/HelveticaNeueLTStd-Lt.otf") format("opentype");
}

$font-ttl: "DIN Condenced", sans-serif;
// $font-nav: "M PLUS Rounded 1c", sans-serif;
$font-nav: "Helvetica", sans-serif;
$font-jp: "Sawarabi Gothic", sans-serif;
$font-en: "Helvetica", sans-serif;
