@font-face {
  font-family: "DIN Condenced";
  font-style: normal;
  font-weight: 600;
  src: url("/assets/font/DIN-Condensed-Bold.woff2") format("woff2"), url("/assets/font/DIN-Condensed-Bold.woff") format("woff"), url("/assets/font/DIN-Condensed-Bold.otf") format("opentype");
}

@font-face {
  font-family: "Helvetica";
  font-style: bold;
  font-weight: 300;
  src: url("/assets/font/HelveticaNeueLTStd-Lt.woff2") format("woff2"), url("/assets/font/HelveticaNeueLTStd-Lt.woff") format("woff"), url("/assets/font/HelveticaNeueLTStd-Lt.otf") format("opentype");
}

@media screen and (min-width: 700px) and (max-width: 999px) {
  header a {
    color: white !important;
  }
  header path {
    fill: white !important;
  }
  header .g__list--sns path {
    fill: white !important;
  }
  header .g__btn--round {
    border: 1px solid white;
  }
}

@media screen and (min-width: 999px) {
  header a {
    color: white !important;
  }
  header path {
    fill: white !important;
  }
  header .g__list--sns path {
    fill: white !important;
  }
  header .g__btn--round {
    border: 1px solid white;
  }
}

.mv {
  height: unset !important;
  min-height: unset !important;
  padding: 0 8%;
  margin: 0 !important;
  background-image: url(/assets/img/top/bg_forest.jpg);
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
}

.mv::before, .mv::after {
  display: block;
  padding: 15% 0 !important;
  content: "";
}

.mv__ttl {
  font-family: "Helvetica", sans-serif;
  font-size: 70px;
  color: white;
  text-shadow: 0 0 10px #555;
}

.mv__ttl small {
  font-size: 40px;
}

.content__txt {
  padding: 0 0 100px 0;
  font-size: 24px;
  text-align: center;
}
