@charset 'UTF-8';
@import "base";

@include pc{
	header {
		a{
			color:white!important;
		}
		path{
			fill:white!important;
		}

		.g__list--sns{
			path{
				fill:white!important;
			}
		}
		.g__btn--round{
			border: 1px solid white;
		}
	}
}

.mv{
	height: unset!important;
	min-height: unset!important;
	padding:0 8%;
	margin:0!important;
	background-image: url(/assets/img/top/bg_forest.jpg);
	background-attachment: fixed;
	background-position: center;
	background-size: cover;
	&::before,&::after{
		display: block;
		padding: 15% 0!important;
		content:"";
	}
}

.mv__ttl{
	font-family: $font-en;
	font-size:70px;
	color: white;
	text-shadow: 0 0 10px #555;
	small{
		font-size:40px;
	}
}

.content__txt{
	padding:0 0 100px 0;
	font-size: 24px;
	text-align: center;
}




